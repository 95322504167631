import { useDispatch, useSelector } from "react-redux";
import { selectActiveAccount } from "../store/accounts/slice";
import { selectPostFilters, selectPostPagination, selectPosts, setPostStatusFilter, setPostsPageIndex } from "../store/posts/slice";
import { useEffect } from "react";
import { getPosts } from "../store/posts/thunks";
import Post from "./Post";
import { FormSelect } from "react-bootstrap";
import Pagination from "./Pagination";

export default function PostsList() {
	const dispatch = useDispatch();
	const account = useSelector(selectActiveAccount);
	const filters = useSelector(selectPostFilters);
	const { posts, postsLoading, postsError } = useSelector(selectPosts);
	const { pageIndex, pageSize, total } = useSelector(selectPostPagination);
	
	useEffect(() => {
		if (account) {
			dispatch(getPosts());
		}
	}, [dispatch, account, filters, pageIndex]);
	
	if (account === null) {
		return <div className="text-center mt-5	 help-text">Please select an account.</div>
	}
	
	let postsList;
	if (postsLoading) {
		postsList = <div className="text-center help-text">Loading posts...</div>
	} else if (postsError) {
		postsList = <div className="text-center help-text error">Error loading posts...</div>
	} else {
		postsList = (
			<div className="d-flex flex-column align-items-center">
				{Object.values(posts).map(post => <Post key={post.id} post={post} />)}
			</div>
		);
	}
	
	const handleStatusFilterChange = (status) => {
		dispatch(setPostStatusFilter(status));
	}
	
	return (
		<div className="container pt-3">
			<h1 className="text-center">Posts</h1>
			Filter by Status:
			<FormSelect defaultValue={null} onChange={e => handleStatusFilterChange(e.target.value)}>
				<option value={null}>All</option>
				<option value="NEW">New</option>
				<option value="APPROVED">Approved</option>
				<option value="REJECTED">Rejected</option>
				<option value="PUBLISHED">Published</option>
			</FormSelect>
			<Pagination isLoading={postsLoading} pageIndex={pageIndex} pageSize={pageSize} total={total} onChange={pageIndex => dispatch(setPostsPageIndex(pageIndex))} />
			{postsList}
			<Pagination isLoading={postsLoading} pageIndex={pageIndex} pageSize={pageSize} total={total} onChange={pageIndex => dispatch(setPostsPageIndex(pageIndex))} />
		</div>
	)
};