import { Container, Row } from "react-bootstrap";
import { selectActiveAccount, selectActiveAccountExtended } from "../store/accounts/slice";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { getAccountExtended } from "../store/accounts/thunks";
import pluralize from "../util/pluralize";

function daysCount(postsCount) {
	return Math.floor(postsCount / 3);
}

export default function AccountInfo() {
	const dispatch = useDispatch();
	const activeAccount = useSelector(selectActiveAccount);
	const activeAccountExtended = useSelector(selectActiveAccountExtended);
	useEffect(() => {if (activeAccount) dispatch(getAccountExtended(activeAccount))}, [dispatch, activeAccount]);
	
	if (!activeAccount || !activeAccountExtended) {
		return "";
	}
	
	const newDaysCount = daysCount(activeAccountExtended.new_posts_count);
	const approvedDaysCount = daysCount(activeAccountExtended.approved_posts_count);
	
	let newDaysClasses = "bg-success text-light";
	if (newDaysCount > 14) {
		newDaysClasses = "bg-danger text-light";
	} else if (newDaysCount > 7) {
		newDaysClasses = "bg-warning text-dark";
	}
	
	let approvedDaysClasses = "bg-danger text-light";
	if (approvedDaysCount > 14) {
		approvedDaysClasses = "bg-success text-light";
	} else if (approvedDaysCount > 7) {
		approvedDaysClasses = "bg-warning text-dark";
	}
	
	return (
		<Container className="mt-3">
			<Row className="d-flex justify-content-between align-items-stretch">
				<div className="col d-flex flex-column">
					<div className={newDaysClasses+" border rounded p-3 d-flex flex-column justify-content-between flex-grow-1 text-center"}>
						Posts to approve:
						<div className="display-1">
							{activeAccountExtended.new_posts_count}
						</div>
						<div>({newDaysCount} {pluralize("day", newDaysCount)})</div>
					</div>
				</div>
				<div className="col d-flex flex-column">
					<div className={approvedDaysClasses+" border rounded p-3 d-flex flex-column justify-content-between flex-grow-1 text-center"}>
						Posts waiting to be published:
						<div className="display-1">
							{activeAccountExtended.approved_posts_count}
						</div>
						<div>({approvedDaysCount} {pluralize("day", approvedDaysCount)})</div>
					</div>
				</div>
			</Row>
		</Container>
	);
}