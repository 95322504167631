import { createSlice } from '@reduxjs/toolkit'
import accountsSlice from './accounts/slice';
import { setActiveAccount } from './accounts/thunks';

export const PAGE_STATUSES = {
	CREATE_ACCOUNT: "create-account",
	ACCOUNT_VIEW: "account-view",
};

const pageSlice = createSlice({
	name: 'page',
	initialState: {
		status: PAGE_STATUSES.ACCOUNT_VIEW,
	},
	reducers: {
		setStatus: (state, action) => {
			state.status = action.payload;
		},
	}
});

// thunk
export const goToAccountEditPage = (account) => async dispatch => {
	await dispatch(setActiveAccount(account?.id ? account : null))
	await dispatch(accountsSlice.actions.setActiveAccountExtended(null))
	await dispatch(accountsSlice.actions.setEditingAccount(account))
	await dispatch(accountsSlice.actions.setEditAccountError(null))
	dispatch(pageSlice.actions.setStatus(PAGE_STATUSES.CREATE_ACCOUNT))
}

// thunk
export const goToAccountViewPage = account => async (dispatch, getState) => {
	await dispatch(setActiveAccount(account))
	await dispatch(accountsSlice.actions.setActiveAccountExtended(null))
	await dispatch(pageSlice.actions.setStatus(PAGE_STATUSES.ACCOUNT_VIEW))
}

export const selectPageStatus = state => state.page.status;

export default pageSlice;