import { createSelector, createSlice } from '@reduxjs/toolkit'

const postsSlice = createSlice({
	name: 'posts',
	initialState: {
		posts: {},
		postsLoading: false,
		postsError: false,
		filters: {
			status: null,
		},
		pagination: {
			pageIndex: 0,
			pageSize: 1,
			total: 0,
		},
	},
	reducers: {
		setPostsInfo: (state, action) => {
			state.posts = {};
			action.payload.posts.forEach(item => {
				state.posts[item.id] = item;
			});
			state.postsLoading = action.payload.postsLoading;
			state.postsError = action.payload.postsError;
			state.pagination = action.payload.pagination;
		},
		setPost: (state, action) => {
			state.posts[action.payload.id] = action.payload;
		},
		setPostStatusFilter: (state, action) => {
			state.filters.status = action.payload
		},
		setPostsLoading: (state, action) => {
			state.postsLoading = action.payload
		},
		setPostsError: (state, action) => {
			state.postsError = action.payload
		},
		setPostsPageIndex: (state, action) => {
			state.pagination.pageIndex = action.payload
		},
	},
});

const selectPostsSimple = state => state.posts.posts;
const selectPostsLoading = state => state.posts.postsLoading;
const selectPostsError = state => state.posts.postsError;

export const selectPosts = createSelector(
	selectPostsSimple, selectPostsLoading, selectPostsError, 
	(posts, postsLoading, postsError) => ({ posts, postsLoading, postsError })
);

export const selectPostPageIndex = state => state.posts.pagination.pageIndex;
export const selectPostPageSize = state => state.posts.pagination.pageSize;
export const selectPostTotal = state => state.posts.pagination.total;
export const selectPostPagination = createSelector(
	selectPostPageIndex, selectPostPageSize, selectPostTotal,
	(pageIndex, pageSize, total) => ({ pageIndex, pageSize, total })
);

export const selectPostFilters = state => state.posts.filters;

export const { setPostsInfo, setPost, setPostsLoading, setPostsError, setPostStatusFilter, setPostsPageIndex } = postsSlice.actions;

export default postsSlice;