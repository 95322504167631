import { faChevronLeft, faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "react-bootstrap";
import "./Pagination.css"
import { debounce } from "lodash"
import { useState } from "react";

const doPageChange = debounce((onChange, setManualChange, newPageIndex) => {
	console.log("page change " + (newPageIndex - 1))
	onChange(newPageIndex - 1);
	setManualChange(false);
}, 1000);

export default function Pagination({ isLoading, pageIndex, pageSize, total, onChange }) {
	const totalPages = Math.ceil(total / pageSize);
	
	const [manualChange, setManualChange] = useState(false);
	const [changingPage, setChangingPage] = useState(false);
	
	// const handlePageChange = (pageIndex) => {
	// 	onChange(pageIndex);
	// }
	
	const handlePreviousClick = () => {
		if (pageIndex > 0) {
			onChange(pageIndex - 1);
		}
	}
	
	const handleNextClick = () => {
		if (pageIndex < totalPages - 1) {
			onChange(pageIndex + 1);
		}
	}
	
	const handlePageChange = e => {
		setManualChange(true);
		let value = e.target.value;
		setChangingPage(value);
		value = Math.min(value, totalPages);
		value = Math.max(value, 1);
		doPageChange(onChange, setManualChange, value);
	}
	
	return (
		<div className="d-flex justify-content-center align-items-center m-2">
			<Button onClick={handlePreviousClick} disabled={pageIndex === 0 || isLoading}>
				<FontAwesomeIcon icon={faChevronLeft} />
			</Button>
			<div className="mx-3">
				<input 
					className="pagination-number-input text-center me-2 rounded border-gray" 
					type="number" 
					value={manualChange ? (parseInt(changingPage)) : (pageIndex + 1)} 
					onChange={handlePageChange} />
				of {totalPages}
			</div>
			<Button onClick={handleNextClick} disabled={pageIndex === totalPages - 1 || isLoading}>
				<FontAwesomeIcon icon={faChevronRight} />
			</Button>
		</div>
	)
};