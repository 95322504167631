import { useDispatch } from "react-redux";
import { Container, Row } from "react-bootstrap";
import { publishPostNow, updatePost } from "../store/posts/thunks";
import { prettyDateTime } from "../util/datetime";
import { confirm } from "../util/popup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { useState } from "react";

export default function Post({ post }) {
	const [isCollapsed, setIsCollapsed] = useState(true);
	
	let borderClass;
	switch(post.status) {
		case "APPROVED":
			borderClass = "border-success";
		break;
		case "REJECTED":
			borderClass = "border-danger";
		break;
		case "PUBLISHED":
			borderClass = "border-warning";
		break;
		default:
			borderClass = "";
		break;
	}
	
	return (
		<Container>
			<Row className={"border mt-3 rounded " + borderClass}>
				<div className="col-12 mt-3">
					<StatusInfo post={post}/>
				</div>
				<div className="col-12 d-flex justify-content-center">
					<div className="border rounded p-3 mt-3 bg-light" style={{width: "100%", maxWidth: "800px"}}>
						<div className="w-100 mb-2 text-center">
							<img className="w-100" src={post.media_url} alt={post.caption} loading="lazy"/>
						</div>
						<p>
							{post.caption}
						</p>
						<p>
							{post.hashtags}
						</p>
					</div>
				</div>
				
				<div className="col-12 collapse" id="collapsibleDiv">
					<PostMetadata title="Media Prompt Generation Prompt" value={post.image_prompt_generation_prompt}/>
					<PostMetadata title="Media Generation Prompt" value={post.image_generation_prompt}/>
					<PostMetadata title="Media Generation Negative Prompt" value={post.image_generation_negative_prompt}/>
					<PostMetadata title="Caption Generation Prompt" value={post.caption_generation_prompt}/>
					<PostMetadata title="Hashtags Generation Prompt" value={post.hashtags_generation_prompt}/>
				</div>
				<div 
					className="btn btn-block" 
					data-bs-toggle="collapse" 
					data-bs-target="#collapsibleDiv"
					onClick={() => setIsCollapsed(!isCollapsed)}>
					<FontAwesomeIcon icon={isCollapsed ? faChevronDown : faChevronUp} className="mr-2"/>
				</div>
			</Row>
		</Container>
	);
}

function PostMetadata({ title, value }) {
	return (
		<div>
			<div><b>{title}:</b></div>
			<pre className="code bg-light p-2 border rounded">{value}</pre>
		</div>
	);
}

function StatusInfo({ post }) {
	const dispatch = useDispatch();
	
	const handleStatusChange = (newStatus) => {
		let newPost = { ...post };
		newPost.status = newStatus;
		dispatch(updatePost(newPost));
	};
	
	const publish = () => {
		confirm(`Are you sure you want to publish this post right now?`).then(ok => {
			if (ok) {
				dispatch(publishPostNow(post));
			}
		});
	}
	
	let status;
	switch(post.status) {
		case "NEW":
			status = <div className="d-flex gap-3">
				<div className="flex-grow-1 btn btn-success btn-block" onClick={() => handleStatusChange("APPROVED")}>Approve</div>
				<div className="flex-grow-1 btn btn-danger btn-block" onClick={() => handleStatusChange("REJECTED")}>Reject</div>
			</div>;
		break;
		case "APPROVED":
			status = <div className="d-flex gap-3 align-items-center justify-content-between">
				<span className="badge bg-success">✅ Approved</span>
				<div className="btn btn-warning" onClick={() => publish()}>🚀 Publish Now!</div>
			</div>;
		break;
		case "REJECTED":
			status = <span className="badge bg-danger">❌ Rejected</span>;
		break;
		case "PUBLISHED":
			status = <span className="badge bg-light text-dark border">✨ Published ✨</span>;
		break;
		default:
			status = <span className="badge bg-dark">Unknown</span>;
		break;
	}
	
	return <div>
		<div>{status}</div>
		{post.status === "PUBLISHED" ? "🕰️ " + prettyDateTime(post.published_at) : null}
	</div>
}