import { setAccounts, setAccountsError, setAccountsLoading, _setActiveAccount, setActiveAccountExtended, setEditAccountError } from './slice';
import api from '../../api';
import { loading } from '../../util/popup';
import { setPostsPageIndex } from '../posts/slice';
import { batch } from 'react-redux';

export const setActiveAccount = (account) => async (dispatch, getState) => {
	batch(() => {
		dispatch(_setActiveAccount(account || null));
		dispatch(setPostsPageIndex(0));
	});
}

export const getAccountExtended = (account) => async (dispatch, getState) => {
	let res = await api(dispatch, getState).get(`/api/accounts/${account.id}/extended`);
	await dispatch(setActiveAccountExtended(res.data));
}

export const getAccounts = () => async (dispatch, getState) => {
	dispatch(setAccountsLoading(true));
	dispatch(setAccountsError(false));
	return api(dispatch, getState).get(`/api/accounts`).then(res => {
		dispatch(setAccounts(res.data));
		dispatch(setAccountsLoading(false));
		dispatch(setAccountsError(false));
	}).catch(() => {
		dispatch(setAccountsLoading(false));
		dispatch(setAccountsError(true));
	});
}

export const addOrUpdateAccount = (account) => async (dispatch, getState) => {
	await dispatch(setEditAccountError(null));
	let finish = loading(account?.id ? "Updating Account..." : "Creating Account...")
	return api(dispatch, getState)[account?.id ? 'put' : 'post'](`/api/accounts`, account).then(async res => {
		console.log("___RES___", res);
		if (res.status !== 200) {
			throw new Error(res.data);
		}
		await dispatch(getAccounts());
		finish();
		return res.data;
	}).catch(res => {
		finish();
		dispatch(setEditAccountError(res.data));
		return Promise.reject(res);
	});
}