import { setPostsInfo, setPostsError, setPostsLoading, setPost } from './slice';
import api from '../../api';
import { loading } from '../../util/popup';
import { getAccountExtended } from '../accounts/thunks';

export const getPosts = () => async (dispatch, getState) => {
	let state = getState();
	let accountID = state.accounts.activeAccount?.id;
	dispatch(setPostsLoading(true));
	dispatch(setPostsError(false));
	let queryParams = {
		account_id: accountID,
		status: state.posts.filters.status,
		page_index: state.posts.pagination.pageIndex,
		page_size: state.posts.pagination.pageSize,
	};
	Object.keys(queryParams).forEach(key => queryParams[key] === null && delete queryParams[key]);
	let url = `/api/posts?${new URLSearchParams(queryParams)}`;
	api(dispatch, getState).get(url).then(res => {
		dispatch(setPostsInfo({
			posts: res.data.data,
			postsLoading: false,
			postsError: false,
			pagination: {
				pageIndex: res.data.pagination.page_index,
				pageSize: res.data.pagination.page_size,
				total: res.data.pagination.total,
			},
		}));
	});
}

export const updatePost = post => async (dispatch, getState) => {
	let close = loading("Updating post...");
	let url = `/api/posts`;
	return api(dispatch, getState).put(url, post).then(res => {
		dispatch(setPost(res.data));
		let account = getState().accounts.activeAccount;
		close();
		return Promise.all([
			dispatch(getAccountExtended(account)),
			dispatch(getPosts()),
		]);
	});
}

export const publishPostNow = post => async (dispatch, getState) => {
	let close = loading("Updating post...");
	let url = `/api/posts/${post.id}/publish`;
	api(dispatch, getState).post(url).then(res => {
		dispatch(updatePost(res.data));
		close();
	});
}