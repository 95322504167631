import { Button } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux'
import './App.css';
import { selectToken } from './store/auth/slice';
import { login, logout, updateTokenStatus } from './store/auth/thunks';
import { selectActiveAccount } from './store/accounts/slice';
import { useEffect } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faPlus } from "@fortawesome/free-solid-svg-icons";
import AccountInfo from './components/AccountInfo';
import AccountSelect from './components/AccountSelect';
import PostsList from './components/PostsList';
import { PAGE_STATUSES, goToAccountEditPage, selectPageStatus } from './store/page';
import AccountForm from './components/AccountForm';

function App() {
	const dispatch = useDispatch();
	useEffect(() => {dispatch(updateTokenStatus())}, [dispatch]);
	const token = useSelector(selectToken);
	
	return token ? <AuthenticatedContent /> : <UnauthenticatedContent />;
}

function AuthenticatedContent() {
	const dispatch = useDispatch();
	const account = useSelector(selectActiveAccount);
	const pageStatus = useSelector(selectPageStatus);
	
	let content;
	switch (pageStatus) {
		case PAGE_STATUSES.CREATE_ACCOUNT:
			// content = <CreateAccountPage />;
			content = <AccountForm />;
			break;
		case PAGE_STATUSES.ACCOUNT_VIEW:
		default:
			content = (
				<div>
					<AccountInfo account={account}/>
					<PostsList />
				</div>
			);
	}
	
	return (
		<div>
			<TopBar />
			<div className="mx-3 pt-3 d-flex gap-2">
				<div className="flex-grow-1">
					<AccountSelect />
				</div>
				{account ? 
					<div className="btn btn-secondary" onClick={() => dispatch(goToAccountEditPage(account))}>
						<FontAwesomeIcon icon={faEdit} />
					</div>
				: ""}
				<div className="btn btn-secondary" onClick={() => dispatch(goToAccountEditPage(null))}>
					<FontAwesomeIcon icon={faPlus} />
				</div>
			</div>
			{content}
		</div>
	);
}

function UnauthenticatedContent() {
	return (
		<div>
			<TopBar />
			<div className="text-center mt-5 help-text">Please login to continue.</div>
		</div>
	);
}

function TopBar() {
	return (
		<div className="bg-light d-flex justify-content-between align-items-center p-3">
			<a href="/" className="mx-3 text-decoration-none text-dark"><h1>F R E D A N I E L</h1></a>
			<AuthButton />
		</div>
	);
}

function AuthButton() {
	const dispatch = useDispatch();
	const token = useSelector(selectToken);
	
	if (token) {
		return <Button onClick={() => dispatch(logout())}>Log Out</Button>;
	} else {
		return <Button onClick={() => dispatch(login())}>Login</Button>;
	}
}

export default App;
