import Swal from 'sweetalert2'

export function confirm(text) {
	return new Promise((resolve) => 
		Swal.fire({
			title: text,
			confirmButtonText: "Confirm",
			showCancelButton: true,
		}).then((result) => resolve(result.isConfirmed))
	);
}

export function loading(text) {
	Swal.fire({
		title: text,
		allowOutsideClick: false,
		showConfirmButton: false,
		willOpen: () => {
			Swal.showLoading();
		},
	});
	return () => Swal.close();
}