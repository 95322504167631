import { Container } from "react-bootstrap";
import { goToAccountViewPage } from "../store/page";
import { useDispatch, useSelector } from "react-redux";
import { selectActiveAccount, selectEditAccountError, selectEditingAccount, setEditingAccount } from "../store/accounts/slice";
import RequiredStar from './RequiredStar';
import { addOrUpdateAccount } from "../store/accounts/thunks";

export default function AccountForm() {
	const dispatch = useDispatch();
	const activeAccount = useSelector(selectActiveAccount);
	const accountFormData = useSelector(selectEditingAccount) || {
		name: '',
		active: false,
		slug: '',
	};
	const editAccountError = useSelector(selectEditAccountError);
	
	let handleChange = (event) => {
		dispatch(setEditingAccount({
			...accountFormData,
			[event.target.name]: event.target.value
		}));
	}
	
	let handleSubmit = async (event) => {
		event.preventDefault();
		
		let accountData = Object.assign({}, accountFormData);
		dispatch(addOrUpdateAccount(accountData)).then(account => {
			dispatch(goToAccountViewPage(account))
		}, () => {});
	}
	
	return (
		<Container>
			<div className="card bg-light m-3 p-3">
				<div className="display-5 text-center">
					{activeAccount ? "Edit Account" : "Create Account"}
				</div>
				{editAccountError && <div className="alert alert-danger mt-3">{editAccountError}</div>}
				<form onSubmit={handleSubmit}>
					<div className="mb-3 form-check">
						<input 
							onChange={e => handleChange({target: {name: 'active', value: e.target.checked}})} 
							className="form-check-input form-check-lg" 
							id="active" 
							name="active" 
							type="checkbox"
							checked={accountFormData?.active}
						/>
						<label htmlFor="active" className="form-check-label text-left d-flex justify-content-start">Active</label>
					</div>
					<div className="mb-3">
						<label htmlFor="name" className="form-label">Name <RequiredStar/></label>
						<input onChange={handleChange} type="text" className="form-control" id="account-form-name" name="name" placeholder="Enter Account Name" value={accountFormData?.name ?? ''} />
					</div>
					<div className="mb-3">
						<label htmlFor="slug" className="form-label">Instagram Account Slug</label>
						<input onChange={handleChange} type="text" className="form-control" id="account-form-slug" name="slug" placeholder="Enter Instagram Account Slug" value={accountFormData?.slug ?? ''} />
					</div>
					<div className="mb-3">
						<div>Instagram Account User ID</div>
						<div className="p-2 mt-2 border bg-light text-muted cursor-disabled rounded">{accountFormData?.instagram_business_account_user_id || <i>Will be set upon activation</i>}</div>
					</div>
					<div className="d-flex justify-content-center gap-3">
						<div className="btn btn-secondary" onClick={() => dispatch(goToAccountViewPage(activeAccount))}>Cancel</div>
						<button type="submit" className="btn btn-primary">{activeAccount ? 'Update' : 'Create'} Account</button>
					</div>
				</form>
			</div>
		</Container>
	);
}