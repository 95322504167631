import { useDispatch, useSelector } from "react-redux";
import { selectAccounts, selectActiveAccount } from "../store/accounts/slice";
import { getAccounts, setActiveAccount } from "../store/accounts/thunks";
import { useEffect } from "react";
import { FormSelect } from "react-bootstrap";

export default function AccountSelect() {
	const dispatch = useDispatch();
	const activeAccount = useSelector(selectActiveAccount);
	useEffect(() => {dispatch(getAccounts())}, [dispatch]);
	const { accounts, accountsLoading, accountsError } = useSelector(selectAccounts);
	
	if (accountsLoading) {
		return <select defaultValue={null} className="form-select" disabled>
			<option>Loading...</option>
		</select>
	}
	
	if (accountsError) {
		return <select defaultValue={null} className="form-select" disabled>
			<option>Error loading accounts...</option>
		</select>
	}
	
	const handleAccountSelectChange = (e) => {
		let selectedAccount = accounts.filter(account => account.id === e.target.value);
		dispatch(setActiveAccount(selectedAccount[0] || null));
	}
	
	return <FormSelect value={activeAccount?.id || ""} onChange={handleAccountSelectChange}>
		<option key="" value="">Select an Account...</option>
		{accounts.map(account => <option key={account.id} value={account.id}>{account.name ? (account.name + " - ") : ""}{account.slug}</option>)}
	</FormSelect>
}