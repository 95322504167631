import { setToken } from "./slice";

let fbLoaded = false;

export const initAuth = () => {
	window.fbAsyncInit = function () {
		window.FB.init({
			appId: process.env.REACT_APP_META_CLIENT_ID,
			cookie: true,
			xfbml: true,
			version: 'v18.0'
		});
		fbLoaded = true;
	};

	(function (d, s, id) {
		var js, fjs = d.getElementsByTagName(s)[0];
		if (d.getElementById(id)) { return; }
		js = d.createElement(s); js.id = id;
		js.src = "https://connect.facebook.net/en_US/sdk.js";
		fjs.parentNode.insertBefore(js, fjs);
	}(document, 'script', 'facebook-jssdk'));
}

let waitForFBLoad = () => {
	return new Promise((resolve, reject) => {
		let interval = setInterval(() => {
			if (fbLoaded) {
				clearInterval(interval);
				resolve();
			}
		}, 100);

		setTimeout(() => {
			clearInterval(interval);
			reject(new Error('Facebook SDK not loaded'));
		}, 10000);
	});
}

export const updateTokenStatus = () => async dispatch => {
	waitForFBLoad().then(() => {
		window.FB.getLoginStatus(function (response) {
			if (response.status === 'connected') {
				dispatch(setToken(response.authResponse.accessToken));
			} else {
				dispatch(setToken(null));
			}
		});
	});
}

export const login = () => async dispatch => {
	waitForFBLoad().then(() => {
		window.FB.login(function (response) {
			if (response.authResponse) {
				dispatch(setToken(response.authResponse.accessToken));
			} else {
				dispatch(setToken(null));
			}
		}, {
			scope: 'instagram_basic,ads_management,pages_read_engagement,pages_show_list,business_management,instagram_content_publish,instagram_manage_comments,instagram_manage_events,instagram_manage_insights,instagram_manage_messages',
			enable_profile_selector: true,
		});
	});
}

export const logout = () => async (dispatch, getState) => {
	window.FB.logout(function (response) {
		dispatch(setToken(null));
	});
}