import { configureStore } from '@reduxjs/toolkit'
import authSlice from './auth/slice'
import accountsSlice from './accounts/slice';
import postsSlice from './posts/slice';
import pageSlice from './page';

const store = configureStore({
	reducer: {
		auth: authSlice.reducer,
		accounts: accountsSlice.reducer,
		posts: postsSlice.reducer,
		page: pageSlice.reducer,
	}, 
}, window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__());

export default store;