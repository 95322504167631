import { createSelector, createSlice } from '@reduxjs/toolkit'

const accountsSlice = createSlice({
	name: 'accounts',
	initialState: {
		accounts: [],
		activeAccount: null,
		activeAccountExtended: null,
		accountsLoading: false,
		accountsError: false,
		editingAccount: null,
		editAccountError: null,
	},
	reducers: {
		setAccounts: (state, action) => {
			state.accounts = action.payload
		},
		_setActiveAccount: (state, action) => {
			state.activeAccount = action.payload
		},
		setActiveAccountExtended: (state, action) => {
			state.activeAccountExtended = action.payload
		},
		setAccountsLoading: (state, action) => {
			state.accountsLoading = action.payload
		},
		setAccountsError: (state, action) => {
			state.accountsError = action.payload
		},
		setEditingAccount: (state, action) => {
			state.editingAccount = action.payload
		},
		setEditAccountError: (state, action) => {
			state.editAccountError = action.payload
		},
	},
});

const selectAccountsSimple = state => state.accounts.accounts;
const selectAccountsLoading = state => state.accounts.accountsLoading;
const selectAccountsError = state => state.accounts.accountsError;

export const selectAccounts = createSelector(
	selectAccountsSimple, selectAccountsLoading, selectAccountsError, 
	(accounts, accountsLoading, accountsError) => ({ accounts, accountsLoading, accountsError })
);

export const selectActiveAccount = state => state.accounts.activeAccount;
export const selectActiveAccountExtended = state => state.accounts.activeAccountExtended;
export const selectEditingAccount = state => state.accounts.editingAccount;
export const selectEditAccountError = state => state.accounts.editAccountError;

export const { 
	setAccounts, 
	_setActiveAccount, 
	setActiveAccountExtended, 
	setAccountsLoading, 
	setAccountsError, 
	setEditingAccount, 
	setEditAccountError
} = accountsSlice.actions;

export default accountsSlice;