import axios from 'axios';
import { logout } from './store/auth/thunks';

const api = (dispatch, getState) => {
	let token = getState()?.auth?.token;
	let headers = {};
	if (token) {
		headers['Authorization'] = `Bearer ${token}`;
	}
	let api = axios.create({
		baseURL: `${process.env.REACT_APP_API_HOST}:${process.env.REACT_APP_API_PORT}`,
		headers: headers,
	});
	
	let errorHandler = error => {
		if (error.response.status === 401) {
			dispatch(logout());
		}
		return Promise.reject(error.response);
	}
	
	return {
		get: function() { return api.get(...arguments).catch(errorHandler) },
		post: function() { return api.post(...arguments).catch(errorHandler) },
		put: function() { return api.put(...arguments).catch(errorHandler) },
		delete: function() { return api.delete(...arguments).catch(errorHandler) },
	}
};

export default api;